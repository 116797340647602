import { render, staticRenderFns } from "./RequiredFieldsDetail.vue?vue&type=template&id=62b52ccb&scoped=true"
import script from "./RequiredFieldsDetail.vue?vue&type=script&lang=js"
export * from "./RequiredFieldsDetail.vue?vue&type=script&lang=js"
import style0 from "./RequiredFieldsDetail.vue?vue&type=style&index=0&id=62b52ccb&prod&scoped=true&lang=css"
import style1 from "./RequiredFieldsDetail.vue?vue&type=style&index=1&id=62b52ccb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62b52ccb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VContainer,VIcon,VProgressLinear,VRow,VSheet,VSkeletonLoader})
