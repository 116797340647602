<template>
  <v-container fluid>

    <v-alert dense type="info" v-if="false">
      Dieses Dokument wurde zur löschung vorgemerkt.
    </v-alert>

    <v-row>
      <!-- Info Card -->
      <v-col cols="12" md="4">
        <DocumentCard/>
      </v-col>

      <!-- Revision Table -->
      <v-col cols="12" md="8">
        <RevisionTable type="FORM"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RevisionTable from "../components/form/info/RevisionTable.vue";
import DocumentCard from "../components/form/info/DocumentCard.vue";
import TemplateDocumentCard from "../components/template/info/TemplateDocumentCard.vue";

export default {
  name: "FormInfoPage",
  components: {TemplateDocumentCard, DocumentCard, RevisionTable},
};
</script>

<style scoped>
</style>
