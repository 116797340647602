import { render, staticRenderFns } from "./RevisionCompare.vue?vue&type=template&id=5c1f008a&scoped=true"
import script from "./RevisionCompare.vue?vue&type=script&lang=js"
export * from "./RevisionCompare.vue?vue&type=script&lang=js"
import style0 from "./RevisionCompare.vue?vue&type=style&index=0&id=5c1f008a&prod&scoped=true&lang=css"
import style1 from "./RevisionCompare.vue?vue&type=style&index=1&id=5c1f008a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c1f008a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBanner,VBottomNavigation,VCol,VContainer,VRow,VSelect,VSheet,VSkeletonLoader})
