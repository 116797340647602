var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}}):_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('language-switcher.title')))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t('language-switcher.description')))])],1)],1),_c('v-card-actions',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-translate")]),_vm._v(" "+_vm._s(_vm.activeLang)+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-menu-down")])],1)]}}]),model:{value:(_vm.languageMenu),callback:function ($$v) {_vm.languageMenu=$$v},expression:"languageMenu"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.languages),function(lang,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeLanguage(lang)}}},[_c('v-list-item-title',[_vm._v(_vm._s(lang.icon + ' ' + lang.title))])],1)}),1)],1)],1)],1)]],2)],1),[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}}):_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v("Light / Dark Mode")]),_c('v-list-item-subtitle')],1)],1),_c('v-card-actions',[_c('div',[(!_vm.$vuetify.theme.dark)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"info","small":"","fab":""},on:{"click":_vm.darkMode}},on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-moon-waxing-crescent")])],1)]}}],null,false,3513394316)},[_c('span',[_vm._v("Dark Mode On")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"info","small":"","fab":""},on:{"click":_vm.darkMode}},on),[_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v("mdi-white-balance-sunny")])],1)]}}])},[_c('span',[_vm._v("Dark Mode Off")])])],1)])],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }