var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Filter")]),_c('v-divider'),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.users,"prepend-icon":"mdi-account","label":"Erstellt von","item-text":"name","item-value":"id","return-object":""},model:{value:(_vm.created_by_filter),callback:function ($$v) {_vm.created_by_filter=$$v},expression:"created_by_filter"}})],1),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.users,"prepend-icon":"mdi-account","label":"Bearbeitet von","item-text":"name","item-value":"id","return-object":""},model:{value:(_vm.updated_by_filter),callback:function ($$v) {_vm.updated_by_filter=$$v},expression:"updated_by_filter"}})],1),_c('v-card-text',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Erstellt am","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.created_at_filter),callback:function ($$v) {_vm.created_at_filter=$$v},expression:"created_at_filter"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.created_at_menu),callback:function ($$v) {_vm.created_at_menu=$$v},expression:"created_at_menu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.created_at_menu = false}},model:{value:(_vm.created_at_filter),callback:function ($$v) {_vm.created_at_filter=$$v},expression:"created_at_filter"}})],1)],1),_c('v-card-text',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bearbeitet am","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.updated_at_filter),callback:function ($$v) {_vm.updated_at_filter=$$v},expression:"updated_at_filter"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.updated_at_menu),callback:function ($$v) {_vm.updated_at_menu=$$v},expression:"updated_at_menu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.updated_at_menu = false}},model:{value:(_vm.updated_at_filter),callback:function ($$v) {_vm.updated_at_filter=$$v},expression:"updated_at_filter"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.resetFilters}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-remove")]),_vm._v(" Filter löschen ")],1),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.emitFilters}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filter anwenden ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }