var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Vektor")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('delete')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-trash-can")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Prefix"},on:{"change":function($event){return _vm.$emit('change', _vm.value)}},model:{value:(_vm.mutableValue.prefix),callback:function ($$v) {_vm.$set(_vm.mutableValue, "prefix", $$v)},expression:"mutableValue.prefix"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":"Name"},on:{"change":function($event){return _vm.$emit('change', _vm.value)}},model:{value:(_vm.mutableValue.name),callback:function ($$v) {_vm.$set(_vm.mutableValue, "name", $$v)},expression:"mutableValue.name"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Suffix"},on:{"change":function($event){return _vm.$emit('change', _vm.value)}},model:{value:(_vm.mutableValue.suffix),callback:function ($$v) {_vm.$set(_vm.mutableValue, "suffix", $$v)},expression:"mutableValue.suffix"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Anzahl der Felder","value":_vm.mutableValue.elements.length,"append-outer-icon":"add","prepend-icon":"remove","rules":[
          function (v) {
            if (v >= 1) {
              return true;
            }
            return 'Der Wert des Feldes muss mindestens 1 sein.';
          }
        ]},on:{"change":function($event){return _vm.changedSize($event)},"click:append-outer":function($event){return _vm.changedSize(_vm.mutableValue.elements.length + 1)},"click:prepend":function($event){return _vm.changedSize(_vm.mutableValue.elements.length - 1)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }