import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import Router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import vueSignature from 'vue-signature'
import DatetimePicker from 'vuetify-datetime-picker'
import Amplify from 'aws-amplify'
import awsConfig from './aws-exports'
import DocumentController from "./DocumentController.js"
import UserController from "./UserController.js"
import i18n from './i18n.js'

Vue.config.productionTip = false

Vue.use(vuetify)
Vue.use(vueSignature)
Vue.use(DatetimePicker)
Vue.use(VueAxios, axios)

Amplify.configure(awsConfig)

store.commit('setConfig', { api_url: awsConfig.apiUrl })

const router = Router()
router.afterEach((to, from) => {
  //console.log('Sent SW Update request')
  document.dispatchEvent(new Event('check-update'))

  if (to.path !== from.path) {
    store.commit('resetSaveState')
  }
  //store.commit('navigationChange', to)
})

Vue.prototype.$formController = new DocumentController(store, "forms")
Vue.prototype.$templateController = new DocumentController(store, "templates")
Vue.prototype.$userController = new UserController(store)

//Vue.prototype.$i18n = i18n

let vue = new Vue({
  i18n,
  router,
  store,
  vuetify,
  beforeCreate () {
    this.$store.commit('init',i18n)
  },
  render: h => h(App)
})

//i18n.locale = 'en'

vue.$userController.setCurrentUserId(localStorage.getItem("currentUserId"))

vue.$mount('#app')
